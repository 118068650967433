<template>
  <div class="settings_manufacturing_tests_worker" v-if="workerid && pipelineid">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='information'" v-on:click="tab='information'">Information</b-nav-item>
      <b-nav-item :active="tab=='logs'" v-on:click="tab='logs'">Logs</b-nav-item>
    </b-nav>

    <div v-if="tab == 'information' && worker != null">

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Name:">
            <b-form-input v-model="worker.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Id:">
            <b-form-input v-model="worker.id" disabled></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="State:">
            <b-form-input v-model="worker.state" disabled></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Pipeline:">
            <b-form-input v-model="pipeline.name" disabled></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Site:">
            <b-form-input v-model="worker.site"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Owner:">
            <b-form-input v-model="worker.owner"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="IP Address:">
            <b-form-input v-model="worker.ipaddress" disabled></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Last check in:">
            <b-form-input v-model="worker.lastcheckin" disabled></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Last known startup:">
            <b-form-input v-model="worker.startup" disabled></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Last known shutdown:">
            <b-form-input v-model="worker.shutdown" disabled></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!--{{ worker }}-->
    </div>

    <div v-if="tab == 'logs'">

    <div class="row mb-3">
      <div class="col">
        <b-button-group>
          <b-button variant="outline-primary" v-on:click="workerlogs"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
        </b-button-group>
      </div>
      <div class="col-lg-4 col-md-12">
        <b-form v-on:submit.prevent="workerlogs">
          <b-input-group>
            <b-input type="text" ref="search" placeholder="Search..." v-model="search" disabled/>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="outline-primary" v-on:click="workerlogs" disabled><b-icon-search></b-icon-search></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
    </div>
      <div class="row">
        <div class="col">
          <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy" style="font-size: 0.9rem">
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(timestamp)="row">
              <div class="text-nowrap" style="font-size: 0.9rem">{{ row.item.timestamp | moment('YYYY-MM-DD HH:mm:ss') }}</div>
            </template>
            <template #cell(level)="row">
              <b-badge :variant="getLevelClass(row.item.level)">{{ row.item.level }}</b-badge>
            </template>
            <template #cell(renderedmessage)="row">
              <span class="text-primary" style="cursor: pointer" v-on:click.prevent="showLog(row.item)">{{ limitstring(row.item.renderedmessage) }}</span>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>
          Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
          <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
            <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
          </b-dropdown>
          rows per page</span>
        </div>
        <div class="col">
          <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
        </div>
      </div>

    </div>

    <b-modal id="logmodal" title="Log message" size="lg" hide-footer>
      <b-form-group label="Local ID:">
        <b-input type="text" :value="currentItem ? currentItem.localid : ''" />
      </b-form-group>
      <b-form-group label="Rendered Message:">
        <b-form-textarea
          id="textarea"
          placeholder="No rendered message..."
          rows="2"
          max-rows="8"
          style="font-family: Courier New, Lucida Console, Monaco; font-size: 0.80rem"
          :value="currentItem ? currentItem.renderedmessage : ''"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Properties:">
        <b-form-textarea
          id="textarea"
          placeholder="No properties..."
          rows="2"
          max-rows="8"
          style="font-family: Courier New, Lucida Console, Monaco; font-size: 0.80rem"
          :value="currentItem ? currentItem.properties : ''"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Exception:">
        <b-form-textarea
          id="textarea"
          placeholder="No exception..."
          rows="2"
          max-rows="8"
          style="font-family: Courier New, Lucida Console, Monaco; font-size: 0.80rem"
          :value="currentItem ? currentItem.exception : ''"
        ></b-form-textarea>
      </b-form-group>
      <b-button class="mt-3 float-right" variant="primary" @click="$bvModal.hide('logmodal')"><b-icon-x-circle></b-icon-x-circle> Close</b-button>
    </b-modal>

  </div>
</template>

<script>
import { testsService } from '@/services'
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'Worker',
  mixins: [
    tablePagination
  ],
  data () {
    return {
      workername: '',
      tab: 'information',
      pipeline: null,
      worker: null,
      currentItem: null,
      fields: ['level',{key: 'timestamp', tdclass: 'text-nowrap'},{key: 'renderedmessage', label: 'Log'}],
      isBusy: false,
      search: '',
      breadcrumb: [
        { text: 'Home', to: '/' },
        { text: 'Settings', to: '/settings' },
        { text: 'Manufacturing', to: '/settings/manufacturing' },
        { text: 'Tests', to: '/settings/manufacturing/tests' },
        { text: '', to: '' },
        { text: 'Workers', to: '' },
        { text: '', active: true }
      ]
    }
  },
  computed: {
    workerid() {
      return this.$route.params.workerid
    },
    pipelineid() {
      return this.$route.params.pipelineid
    },
  },
  watch: {
    currentPage() {
      this.workerlogs()
    },
    currentPageSize() {
      this.workerlogs()
    }
  },
  methods: {
    getLevelClass(level) {
      switch(level) {
        case 'Information': { return 'info' }
        case 'Warning': { return 'warning' }
        case 'Error': { return 'danger' }
        case 'Fatal': { return 'danger' }
        default: { return 'secondary' }
      }
    },
    limitstring(instr) {
      if(instr.length > 110) { return instr.substring(0, 110) + '...'; } else { return instr }
    },
    async loaddata() {

      this.pipeline = await testsService.getById(this.pipelineid)
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      this.breadcrumb[4] = { text: this.pipeline.name, to: `/settings/manufacturing/tests/${this.pipelineid}` };
      this.breadcrumb[5] = { text: 'Workers', to: `/settings/manufacturing/tests/${this.pipelineid}?tab=workers` }

      this.worker = await testsService.getWorkerById(this.pipelineid, this.workerid)
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      this.breadcrumb[6].text = this.worker.name;

      await this.workerlogs()

    },
    async workerlogs() {
      this.isBusy = true

      let logs = await testsService.getWorkerLogs(this.pipelineid, this.workerid, this.search, this.currentPageSize, this.currentPage)
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      this.totalItems = logs.count
      this.items = logs.data

      this.isBusy = false
      return logs
    },
    activeRows() {
      let pageRowEnd = this.currentPageSize * this.currentPage
      let pageRowStart = this.currentPageSize * (this.currentPage - 1)
      if(pageRowEnd > this.totalItems) {
        return pageRowStart + (this.totalItems - pageRowStart)
      } else {
        return pageRowStart + this.currentPageSize
      }
    },
    showLog(item){
      this.currentItem = item;
      this.$bvModal.show('logmodal')
    }
  },
  async mounted() {
    if(this.$route.query.tab) { this.tab = this.$route.query.tab }
    this.loaddata()
  }
}
</script>
